@import './mixins.scss';
@import './variables.scss';
@import './include-media.scss';


.page {
  margin-bottom: 100px;
}

.container {
  max-width: 900px;
  margin: 0 auto;
  @include media('<940px') {
    padding: 0 20px;
  }
}

.menu {
  @include media('<1400px') {
    display: none;
  }
  position: fixed;
  padding: 20px 30px;
  width: 200px;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  top: 40px;
  left: calc(100vw / 2 - 700px);
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    li {
      a {
        text-decoration: none;
        color: #111;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.header {
  padding: 45px 0 80px 0;
}
.headerText {
  color: #444;
}

.block {
  h2 {
    margin: 60px 0 35px 0;
    font-size: 28px;
  }
  h3 {
    font-size: 24px;
    margin: 30px 0 20px 0;
    font-weight: 500;
  }
}

.videoFrame {
  width: 560px;
  height: 315px;
  width: calc(100vw - 40px);
  height: calc((100vw - 40px) * 0.5625);
  @include media('>=940px') {
    width: 900px;
    height: 506px;
  }
}